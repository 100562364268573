.address {
  display: grid;
}

.address1 {
  grid-row: 5;
}

.address2 {
  grid-row: 4;
}

.city {
  grid-row: 3;
}

.state {
  grid-row: 2;
}

.zip {
  grid-row: 1;
}

@media (min-width: 44rem) {
  .address {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.625rem;
  }

  .address1 {
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .address2 {
    grid-column: 3;
    grid-row: 2;
  }

  .city {
    grid-column: 2;
    grid-row: 2;
  }

  .state {
    grid-column: 1;
    grid-row: 2;
  }

  .zip {
    grid-column: 1 / 4;
    grid-row: 1;
  }
}
