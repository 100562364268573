.footer {
  margin-top: 2rem;
  text-align: center;
}

.terms {
  max-width: 14rem;
  margin: 0.5rem auto 1rem;
  text-align: center;
}

.error {
  margin-bottom: 1rem;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.errorMessage {
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}
