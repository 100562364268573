.breadcrumbs {
  display: flex;
  margin-bottom: 2rem;
}

.breadcrumb {
  display: flex;
  margin-left: 0.5rem;
  /* align-items: center; */
}

.breadcrumb:first-child {
  margin-left: 0;
}

.breadcrumb:last-child div {
  display: none;
}

.link {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.icon {
  display: flex;
  align-items: center;
}
