.product {
  position: relative;
  display: grid;
  /* hide quantity */
  grid-template-columns: 4.5rem auto;
  column-gap: 1rem;
  width: 100%;
  min-height: 5rem;
}

.image {
  height: 4.5rem;
  width: 4.5rem;
  overflow: hidden;
  border-radius: 4px;
}

.flag {
  display: inline-block;
  margin-bottom: 0.25rem;
  padding: 0.125rem 0.375rem;
  border-radius: 4px;
}

.name {
  margin-bottom: 0.25rem;
}

/* hide quantity */
.quantity {
  display: none;
}

/* hide price */
.prices {
  display: none;
}

@media (min-width: 44rem) {
  .product {
    grid-template-columns: 4.5rem auto;
  }

  .image {
    height: 4.5rem;
    width: 4.5rem;
    overflow: hidden;
  }
}

.blurb {
  display: none;
}

.detailsButton {
  display: none;
}
