.p {
  margin-bottom: 1rem;
}

.types {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.type {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;

  padding: 1rem;
  text-align: left;
}

.icon {
  width: 100%;
  min-width: 5.5rem;
}
