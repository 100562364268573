.line {
  display: flex;
  gap: 0.625rem;
}

.line > * {
  flex: 1 1 0;
}

.subheading {
  margin-bottom: 1rem;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
