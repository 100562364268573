.clickableCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: inherit;
  text-align: left;
  padding: 1rem 0;
}

.icon {
  flex: 0;
}

.name {
  margin-bottom: 0.25rem;
}

.date:not(:last-of-type):after {
  content: ' \02010  ';
}

.venue:not(:last-of-type):after {
  content: ' \000B7  ';
  font-weight: 800;
}
