/* .feature {
  width: 27.5rem;
} */

.image > * {
  margin: 1rem auto;
  display: block;
  border-radius: 8px;
}

.alert {
  margin-bottom: 1rem;
}
