.name {
  /* truncate text */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image {
  overflow: hidden;
}

.addToCart {
  position: absolute;
  bottom: 1.5rem;
  right: 0.5rem;
}

.imageContainer {
  position: relative;
}
