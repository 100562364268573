.wrap {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.quantity {
  width: fit-content;

  border-radius: 1.5rem;
  border-style: solid;
  border-width: 1px;
}

.add {
  flex: 1;
}

.toast {
  width: 100%;
  max-width: 23.75rem;
}
