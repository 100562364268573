.header {
  grid-column: span 2;
  margin-bottom: 0.5rem;
}

.column {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info {
  order: 1;
}

.content {
  order: 2;
}

.back {
  margin-bottom: 1rem;
}

.branch {
  padding: 1rem;
  margin-top: 1rem;
}
.flag {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.section:not(:last-of-type) {
  margin-bottom: 1.5rem;
}

.eventImage {
  display: flex;
  max-width: 6rem;
  max-height: 6rem;
  margin-bottom: 0.5rem;
}

.eventImage > img {
  max-width: 100%;
  max-height: 100%;

  object-fit: contain;
  object-position: 50% 50%;
}

.requirement:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.data {
  word-break: break-word;
}

.spacing {
  margin-bottom: 0.5rem;
}

.itemList {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.orderActions {
  margin-top: 1rem;
}

.orderActions > * {
  height: 3rem;
  margin-bottom: 0.5rem;
}

.smallSpacing {
  margin-bottom: 0.25rem;
}

.leftColumn {
  margin-bottom: 1.5rem;
}

.historyItem:not(:last-of-type) {
  margin-bottom: 1rem;
}

.helpLink {
  margin-top: 1rem;
  text-align: center;
}

.totalPrice {
  margin-left: 5.5rem;
  margin-top: 0.5rem;
}

.item {
  margin-top: 1.5rem;
}

.twoColumn {
  margin-top: 1.5rem;
}

@media (min-width: 44rem) {
  .header {
    margin: 0;
  }

  .column {
    grid-column: span 1;
  }

  .info {
    order: 2;
  }

  .content {
    order: 1;
  }

  .align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-direction: row-reverse;
  }

  .flag {
    margin: 0;
  }

  .action {
    width: auto;
  }

  .summary {
    grid-row: 2;
    grid-column: 2 / 3;
    align-self: flex-start;
    width: 100%;
  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  .leftColumn {
    margin-bottom: 0;
  }

  .itemList {
    margin-top: 0;
  }

  .item {
    display: grid;
    grid-template-columns: 3.5rem auto 4.5rem;
    column-gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .orderActions {
    display: block;
    width: 11.75rem;
    margin-top: 0rem;
  }

  .orderActions > * {
    height: inherit;
    margin-bottom: 0.5rem;
  }

  .item {
    grid-template-columns: 6.5rem auto fit-content(6.5rem);
    column-gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .totalPrice {
    display: inherit;
    justify-content: end;
    margin: 0;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .helpLink {
    margin: 0;
  }

  .twoColumn {
    display: grid;
    grid-template-columns: auto 4.5rem;
    column-gap: 1.5rem;
    margin-top: 1.5rem;
  }
}
