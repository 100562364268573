.page {
  justify-content: flex-start;

  padding: 1.5rem 1rem 2rem;
  max-width: 44rem;
}

.title {
  margin-bottom: 1.5rem;
}

.subText {
  margin-bottom: 1.5rem;
}

.align {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.icon {
  width: 5.5rem;
}

.center {
  display: grid;
  align-content: center;
}

.order {
  margin-bottom: 1rem;
}

.showAll {
  margin: 0rem auto;
  justify-content: center;
  cursor: pointer;
}

.item {
  margin-bottom: 2rem;
}

.button {
  width: 9rem;
  margin-left: auto;
  margin-top: 1.5rem;
}

.itemImage {
  height: 3.5rem;
  width: 3.5rem;
  overflow: hidden;
  border-radius: 4px;
}

.scrollbar {
  max-height: calc(50vh);
}
