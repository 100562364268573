.list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

.catalog {
  margin: 0 auto 3.5rem;
}

@media (min-width: 44rem) {
  .list {
    grid-template-columns: 1fr 1fr 1fr; /* min of 12 */
  }
}

@media (min-width: 44rem) {
  .catalog {
    grid-template-columns: 1fr 1fr; /* min of 10 */
  }
}

@media (min-width: 68rem) {
  .catalog {
    grid-template-columns: 1fr 1fr 1fr; /* min of 12 */
  }
}

@media (min-width: 92rem) {
  .catalog {
    grid-template-columns: 1fr 1fr 1fr 1fr; /* min of 14 */
  }
}

@media (min-width: 116rem) {
  .catalog {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* min of 16 */
  }
}

@media (min-width: 132rem) {
  .catalog {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /* min of 18 */
  }
}
