.page {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.main {
  flex: 1;

  width: 100%;
  height: 100%;
}

.saveButton:hover {
  background-color: #e6f3fc;
}

.bjcc {
  color: #7a884d;
  border-color: #7a884d;
}

.cajun {
  color: #7b0015;
  border-color: #7b0015;
}

.exportButton:hover {
  background-color: rgba(9, 15, 20, 0.1);
}

.startOverButton:hover {
  background-color: rgba(9, 15, 20, 0.1);
}
