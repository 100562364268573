.error {
  margin-top: 1rem;
}

.button {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer {
  text-align: center;
}

.form {
  margin-top: 1.5rem;
}

.p:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.errorMessage {
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}
