.heading {
  display: none;
}

.align {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.date {
  margin-top: 0.25rem;
}

.section {
  margin-bottom: 2rem;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.alert {
  margin-bottom: 2rem;
}
.itemHeader {
  margin-bottom: 1rem;
}

.itemSubHeader {
  margin-top: 0.5rem;
}

.divider {
  margin-top: 1rem;
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 1rem;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

@media (min-width: 44rem) {
  .heading {
    display: flex;
    margin-bottom: 1rem;
  }
}
