body {
  height: 100vh;
  width: 100vw;
}

body.sidebar-nav-open {
  overflow-y: hidden;
}

@media (min-width: 44rem) {
  body.sidebar-nav-open {
    overflow-y: initial;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-height: 100%;
  min-width: 100%;
}

@media print {
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
}

*[hidden] {
  display: none;
}

/* Hide "spinner" on number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  /* Firefox */
  -moz-appearance: textfield;
}
