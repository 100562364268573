.sidebar {
  padding-top: 2rem;
  padding-left: 1rem;
}

.section {
  scroll-margin-top: 4rem;
  margin-bottom: 1rem;
}

.heading {
  margin-bottom: 1rem;
}

.button {
  display: inline-flex;

  min-height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin-top: 0.25rem;
}

.button:not(.active):not(:hover) {
  background: transparent;
}

.test > * > strong {
  font-weight: 700;
}
