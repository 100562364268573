.product {
  position: relative;
  display: grid;
  grid-template-columns: auto 2.5rem 5rem;
  column-gap: 1rem;
  width: 100%;
  padding: 0.5rem 0;
}

.flag {
  display: inline-block;
  margin-bottom: 0.25rem;
  padding: 0 0.375rem;
  border-radius: 4px;
}

.name {
  margin-bottom: 4px;
}

.quantity:before {
  content: '\000D7';
}

.blurb {
  display: none;
}

.detailsButton {
  display: none;
}

.image {
  display: none;
}
