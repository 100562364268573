.feedback {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 2rem;
}

.loading {
  margin-right: 1rem;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item:not(:last-of-type) {
  border-bottom-width: 1px;
  border-style: solid;
}
