.desktop {
  display: none;
}

.wrap {
  overflow: visible;
  z-index: 1;
}

.button {
  display: flex;

  height: 3rem;
}

.static {
  justify-content: center;
  align-items: center;
  width: 4.5rem;

  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.static:not([disabled]) {
  cursor: pointer;
}

.dynamic {
  align-items: stretch;
  border-radius: 1.5rem;
}

.quantity {
  width: fit-content;

  border-radius: 1.5rem;
  border-style: solid;
  border-width: 1px;
}

@media (min-width: 44rem) {
  .desktop {
    display: initial; /* override mobile style */
  }

  .mobile {
    display: none;
  }
}
