.page {
  max-width: 30rem;
  padding: 2rem 1rem;
}

.image {
  margin-bottom: 1.5rem;
  text-align: center;
}

.title,
.body {
  margin-bottom: 1rem;
}

.feedback {
  margin-top: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 1.5rem;
}

.header {
  text-align: center;
}
