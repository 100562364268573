.wrap {
  display: flex;
  height: 100%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
