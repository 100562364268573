.page {
  margin: 0;
  min-height: 100vh;
  max-height: 100%;
}

@media screen {
  .page {
    padding: 1rem;
  }
}

.main {
  flex: 1;
  max-width: 44rem;
  width: 100%;
  margin: 0 auto;
}
