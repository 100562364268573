.p {
  margin-bottom: 1rem;
}

.subtext {
  margin-bottom: 0.5rem;
}

.exhibitor {
  margin: 0.5rem 0 1rem;
}

.buttons {
  margin-bottom: 1rem;
}

.option:not(:last-of-type) {
  margin-right: 0.5rem;
}
