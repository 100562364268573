.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.divider {
  margin-top: 1rem;
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 1rem;
}

.label {
  white-space: nowrap;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.caption {
  margin-top: 1rem;
}

.tooltip {
  width: 9rem;
}
