.page {
  max-width: 100%;
  width: 44rem;
  padding: 2.5rem 1rem;
  justify-content: flex-start;
}

.form {
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 44rem) {
  .form {
    grid-template-columns: 12rem auto;
  }

  .legend {
    grid-column: 1 / 3;
  }
}
