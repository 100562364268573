.title {
  margin-bottom: 0.5rem;
}

.info {
  margin-bottom: 1rem;
}

.p {
  margin-bottom: 0.5rem;
}

.pair {
  display: flex;
  gap: 1ch;
}

.label:after {
  content: ':';
}
