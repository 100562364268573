.toolbar {
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
}

.children {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.logo {
  flex: 0 0 auto;
  height: 2.5rem;
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18.75rem;
  max-width: 100vw;
  height: 100%;
}

.footer {
  padding: 1.5rem;
}
