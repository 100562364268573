.featureImage {
  margin-top: 1rem;
  border-radius: 4px;
  max-width: 100%;
  box-shadow: 0 8px 16px rgba(48, 49, 51, 0.1);
}

.alertText {
  margin: 0.75rem 0;
}

.list {
  list-style: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.buttons {
  margin-bottom: 1rem;
}
