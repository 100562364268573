.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.5rem;
  width: 100%;
  padding: 1rem;

  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.align {
  display: flex;
  align-items: center;
}

.cart {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.wrap {
  position: sticky;
}

.content {
  top: 100%;
  z-index: -1;

  height: 0;
  width: 100%;
  overflow: hidden;
}

/* .content.open */
.open {
  height: auto;
}

@media (min-width: 44rem) {
  .wrap {
    position: fixed;
    bottom: 0;

    overflow: hidden;
    width: inherit;

    /* override inline style (for mobile) */
    z-index: auto !important;
  }

  .group {
    display: none;
  }

  .content {
    position: static;
    height: 100%;
  }

  /* .content.open */
  .open {
    /* in case open is still set, and screen is resized */
    height: 100%;
  }
}
