.fieldset {
  margin-top: 1rem;
  display: flex;
  gap: 0.625rem;
}

.fieldset > * {
  flex: 1 1 0;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}
