.wrap {
  position: relative;

  min-width: 10rem;
  max-width: 22rem;
  height: 100%;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;

  padding: 1rem 1rem 0;
  border-radius: 4px; /* TODO: this should reference theme */
  border-style: solid;
  border-width: 1px;
}

.inactive {
  border-color: transparent;
}

.add {
  display: flex;
  justify-content: center;
  height: 3rem;
  min-width: 3rem;
}

.button {
  border-radius: 1.5rem;
}

.quantity {
  position: absolute;
  right: 1.75rem;
  top: 17.5rem;

  z-index: auto;
}
