.main {
  flex: 1;

  margin: 0 auto;
  width: 63rem;
  max-width: 100%;
}

.title {
  margin-bottom: 2.5rem;
}

.content {
  padding: 1rem;
}

.footer {
  flex-grow: 0;
}

@media (min-width: 44rem) {
  .sidebar {
    width: 11rem !important; /* override default in Sidebar */
  }

  .content {
    margin-left: 11.5rem; /* allow space for sidebar */

    padding: 2rem 1rem 0rem;
  }
}
