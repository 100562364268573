.page {
  justify-content: flex-start;

  padding: 1.5rem 1rem 2rem;
  max-width: 35rem;
}

.heading {
  margin-bottom: 1rem;
}

.title {
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 0.5rem;
}

.steps {
  list-style: decimal;
  list-style-position: outside;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.line {
  margin-bottom: 1.5rem;
  border-top-width: 1px;
  border-top-style: solid;
}

.dropdown {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
}

.button {
  height: 3.5rem;
}
