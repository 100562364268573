.links {
  margin-top: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.description {
  display: flex;
}

.link {
  margin-left: 0.25rem;
}

.divider {
  border-right-width: 1px;
  border-right-style: solid;
  height: 1.25rem;
}
