.wrap,
.cards {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.wrap {
  flex-direction: row-reverse;
  justify-content: left;
  margin-bottom: 0.5rem;
}
