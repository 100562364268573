.icon {
  display: flex;
}

/* stop the search icon from flipping */
.value button {
  transform: none;
}

/**
 * Support making the input grey, because
 * filled input doesn't work in AutoComplete
 */
.input {
  border-radius: 4px;
}

.input fieldset {
  border-color: inherit;
}
