.address {
  display: grid;
}

.address1 {
  grid-row: 3;
}

.address2 {
  grid-row: 2;
}

.city {
  grid-row: 1;
}

.zip {
  grid-row: 4;
}
