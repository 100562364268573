.top {
  margin-top: 3.5rem; /* leave room for header */
}

.wrap {
  display: flex;
  align-items: center;
}

.plain {
  padding: 0.5rem 1rem;
}

.closable {
  padding-right: 0.25rem;
  gap: 0.25rem;
}
