.steps {
  margin: 1.5rem auto 0;
  padding: 0 1rem;
  max-width: 35rem;
}

.summary {
  padding: 1rem 1rem 0;
  background-color: rgba(243, 243, 243, 0.5);
  overflow-y: auto;
}

@media (min-width: 44rem) {
  .summary {
    max-width: 100%;
    top: 2.5rem;
    padding: 2rem 2rem 0;
  }
}
