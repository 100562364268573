.scrollbar {
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
