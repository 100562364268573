.inline {
  display: inline;
}

.inline > .label {
  display: none;
}

.inline > .value {
  display: inline;
}

.block > .label,
.block > .value {
  display: inline;
}

.block > .label:after {
  content: ': ';
}
