.nav {
  padding: 1.5rem;
}

.name {
  padding-bottom: 0.5rem;
}

.item {
  margin-bottom: 0.5rem;
}
.settings {
  margin-bottom: 0.5rem;
}
