.p {
  margin-bottom: 1rem;
}

.measurements {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.25rem;
  align-items: center;
}

.x:after {
  /* vertically align the x */
  content: '\000A0';
  display: block;
}

.more {
  float: left;
  margin: 1.5rem;
}
