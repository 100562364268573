.main {
  flex: 1;

  margin: 0 auto;
  width: 63rem;
  max-width: 100%;
}

.content {
  padding: 1rem;
}

@media (min-width: 44rem) {
  .sidebar {
    width: 14rem !important; /* override default in Sidebar */
  }

  .content {
    margin-left: 14rem; /* allow space for sidebar */

    padding: 2rem 1rem;
  }
}
