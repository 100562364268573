.header {
  margin-bottom: 16px;
}

.legend {
  margin-bottom: 1rem;
}

.line {
  display: flex;
  gap: 0.625rem;
}

.line > * {
  flex: 1 1 0;
}

.submit {
  text-align: right;
}
