.remove,
.trash {
  display: flex;
  align-items: center;
}

.trash {
  position: relative;
  left: -0.125rem;
}
