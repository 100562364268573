.page {
  display: flex;
  flex-direction: column-reverse;
  min-height: calc(100vh - 4rem);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.footer {
  flex-grow: 0;
}

@media (min-width: 44rem) {
  .page {
    display: flex;
    flex-direction: row;
  }
  .main {
    flex: 60%;
    margin: 0.5rem auto 0;
    padding: 0 1.5rem;
  }
  .summary {
    width: 40%;
  }
}
