.wrap {
  max-width: 72rem;
  padding: 2rem 1rem 0;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}

.details {
  max-width: 28rem;
}

@media (min-width: 52rem) {
  .columns {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .name,
  .details {
    grid-column: 2;
  }

  .spotlight {
    grid-column: 1;
    grid-row: 1/3;
  }
}

.name {
  margin-bottom: 1rem;
}

.spotlight {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  min-width: 15rem;
  max-width: 36rem;
}

.flag {
  padding: 0.25rem 0.5rem;
}

.flag.solo {
  display: inline-block;
  border-radius: 4px;
}

.flag.accompaniment {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 8px;
}

.thumb,
.spotlight {
  border-radius: 4px;
  overflow: hidden;
}

.thumb > img,
.spotlight > img {
  width: 100%;
}

.section {
  margin: 1.5rem 0.25rem;
}

.faqs {
  margin: 1rem 0 2rem;
}

.heading {
  margin-bottom: 0.5rem;
}

.subText {
  margin: 1rem 0;
}

.subSection {
  margin-bottom: 1.5rem;
}

.required {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.required:first-of-type {
  margin-top: 1rem;
}

.required:not(:last-of-type) {
  margin-bottom: 1rem;
}

.thumb {
  flex: 0 0 3rem;
  font-size: 0;
  overflow: hidden;
}

.questions {
  display: flex;
  margin-bottom: 2.5rem;
}

.questionButton {
  margin-right: 0.5rem;
}

.suggested {
  width: 100%;
}
