.link {
  display: block;
  width: 100%;
  height: 100%;

  border-radius: 4px; /* TODO: this should reference theme */
  border-style: solid;
  border-width: 1px;

  cursor: pointer;
}

.inactive {
  border-color: transparent;
  cursor: auto;
}

.columns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.info {
  flex-grow: 1;
}

.spacing {
  margin-bottom: 0.5rem;
}

.buttons {
  display: none;
}

@media (min-width: 44rem) {
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .buttons > * {
    width: 10rem;
  }
}
