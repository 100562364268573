.header {
  margin-bottom: 1rem;
}

/* TODO: Make the height work with flex: 1 */
.defaultText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: rgba(243, 243, 243, 0.5);
  padding: 0 2.5rem;
  height: 32rem;
}

.section {
  margin-bottom: 1.5rem;
}

.subtitle {
  margin-bottom: 0.5rem;
}

.underline {
  text-decoration: underline;
}

.list {
  padding-left: 1rem;
  list-style-type: decimal;
}

.item {
  margin-bottom: 1rem;
}

.question {
  margin-bottom: 0.5rem;
}

.option:not(:last-of-type) {
  margin-right: 0.5rem;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  margin-top: 2rem;
}

.error {
  margin-top: 0.5rem;
}

.warning {
  margin-left: -1rem;
  margin-bottom: 1rem;
}
