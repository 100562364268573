.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.title {
  margin-bottom: 1rem;
}

.line {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  border-top-width: 1px;
  border-top-style: solid;
}

.list {
  padding-left: 1rem;
  list-style-type: decimal;
}

.section {
  margin-bottom: 1rem;
}

.card {
  display: flex;
  gap: 1rem;
}

.actions {
  display: flex;
  margin-top: 2rem;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.legend {
  max-width: 14rem;
}

.align {
  display: flex;
  gap: 0.25rem;
}
