.item {
  display: list-item;
}

.button {
  display: inline-flex;

  min-height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
}

.button:not(.active) {
  background: transparent;
}

.icon {
  min-width: 2.25rem;
  color: inherit;
}

.text {
  display: contents;
  margin: 0;
  line-height: 1.25;
}
