.image {
  display: flex;
  max-width: 12rem;
  max-height: 12rem;

  margin: 0 auto 1rem;
  /* padding: 0.5rem;
  border-radius: 4px;
  border-style: solid; */
  /* border-width: 1px; */
  justify-content: center;
}

.section {
  margin-top: 1rem;
}

.image > img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;

  object-fit: contain;
  object-position: 50% 50%;
}

.item {
  word-break: break-word;
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
}

.deadlineDate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.nowrap {
  white-space: nowrap;
}

.spacing {
  margin: 0 1ch;
}

.inline {
  display: inline;
}
