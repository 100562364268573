.card:first-of-type {
  margin-top: 1rem;
}

.card:not(:last-of-type) {
  margin-bottom: 1rem;
}

.section:not(:last-of-type) {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
