.p {
  margin-bottom: 1rem;
}

.area:not(:last-of-type) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.heading {
  margin-bottom: 0.5rem;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
