.section {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  margin-top: 2rem;
}

.line {
  display: flex;
  gap: 0.625rem;
}

.line > * {
  flex: 1 1 0;
}

.alert {
  margin-bottom: 1.5rem;
}
