.main {
  display: grid;
  grid-template-columns: 1fr;

  gap: 1rem;
  padding: 1rem 1rem 2rem;
  margin: 0 auto;

  width: 73rem;
  max-width: 100%;
}

@media (min-width: 44rem) {
  .main {
    padding-top: 2.5rem;
    grid-template-columns: 5fr 2fr;
  }

  .sidebar {
    display: none;
  }
}
