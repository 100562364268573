.card {
  display: flex;
  flex-direction: column;
  height: 100%;

  /* padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px; */
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
}

.content:last-child {
  padding-bottom: 1rem;
}

.product {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.quantity {
  display: none;
}

.prices {
  flex: 1;
  margin-bottom: 1rem;
}

.product > :not(.prices) {
  flex: 0;
}

.image {
  border-radius: 4px;
}

@media (min-width: 44rem) {
  .image {
    border-radius: 4px;
  }
}

.name {
  margin-bottom: 4px;
}

.name:hover {
  color: #1e73ba;
}

.flag {
  position: absolute;
  top: calc(1rem + 1px);
  left: calc(1rem + 1px);

  padding: 0.25rem 0.5rem;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 8px;
}

.detailsButton {
  margin-top: 1rem;
}
