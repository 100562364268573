.column {
  display: flex;
  flex-direction: column;
}

.column li {
  text-align: right;
}

.inline {
  display: flex;
  gap: 0.5rem;
}
