.link {
  display: block;
  width: 100%;
  height: 100%;
  /* text-decoration: none; */

  border-radius: 4px; /* TODO: this should reference theme */
  border-style: solid;
  border-width: 1px;

  cursor: pointer;
}

.inactive {
  border-color: transparent;
  cursor: auto;
}

.alert {
  margin-bottom: 1rem;
}

.columns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.eventImage {
  flex: 1 0 100%;
  max-width: 6rem;
  max-height: 6rem;
  display: flex;
}

.eventImage > img {
  max-width: 100%;
  max-height: 100%;

  object-fit: contain;
  object-position: 50% 50%;
}

.date {
  margin-bottom: 0.5rem;
}

.info {
  text-align: left;
  flex-grow: 1;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttons {
  display: none;
}

.eventDetails {
  margin-bottom: 0.5rem;
}

.divider {
  margin: 0 0.5rem;
  border-right-width: 1px;
  border-right-style: solid;
  height: 1rem;
}

@media (min-width: 44rem) {
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .buttons > * {
    width: 9rem;
  }
}
