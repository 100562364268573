.title {
  margin-bottom: 0.5rem;
}

.group {
  margin-bottom: 1rem;
}

.group > li {
  margin-bottom: 0.5rem;
}
