.button {
  position: relative;
}

.submitting {
  visibility: hidden;
}

.loading {
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: center;
}
