.container {
  max-width: 65rem;
  width: 88%;
}

.video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}
