.address {
  display: grid;
}

@media (min-width: 44rem) {
  .address {
    grid-template-columns: 1fr 1fr;
    column-gap: 0.625rem;
  }

  .address1 {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .address2 {
    grid-column: 1 / 4;
    grid-row: 2;
  }

  .state {
    grid-column: 3;
    grid-row: 3;
  }

  .city {
    grid-column: 2;
    grid-row: 3;
  }

  .zip {
    grid-column: 1;
    grid-row: 3;
  }
}
