.toolbar {
  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
}

.children {
  flex: 1;
}

.logo {
  flex: 0 0 auto;
  height: 2.5rem;
}
