.wrap {
  flex: 1;
  height: 100%;

  margin: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  margin-bottom: 1rem;
}

.alert {
  margin-bottom: 1rem;
}
