.links {
  margin-top: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.divider {
  border-right-width: 1px;
  border-right-style: solid;
  height: 1.25rem;
}
