.button {
  text-transform: none;
  position: relative;
  z-index: 1;
}

.background {
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  transition: inherit;
  transition-property: opacity;
}

.selected {
  opacity: 0.1;
  transition: inherit;
  transition-property: opacity;
}
