.page {
  justify-content: flex-start;

  padding: 2.5rem 1rem 2rem;
  max-width: 44rem;
}

.hello {
  margin-bottom: 1rem;
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.new {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: right;
}

.listHeading {
  text-align: left;
}

.upcoming {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  text-align: right;
}

.empty {
  text-align: center;
}

.message {
  max-width: 18rem;
  margin: 0 auto 1rem;
}
