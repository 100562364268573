.list {
  padding: 0 1rem;
}

.item:first-of-type {
  margin-top: 1rem;
}

.link {
  display: inline-flex;
  width: 100%;
  padding: 0.625rem;
  border-radius: 4px;
  text-align: left;
}
