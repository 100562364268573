.title {
  margin: 1rem 0;
}

.listHeading {
  margin-bottom: 1rem;
}

.section {
  margin-bottom: 2rem;
}

.outlined {
  text-align: center;
}

.empty {
  text-align: center;
  padding: 2.75rem 0 1.75rem;
}

.filters {
  margin-bottom: 1rem;
}

.message {
  max-width: 16rem;
  margin: 0 auto 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.row > * {
  flex: 0 0 auto;
}

.button {
  white-space: nowrap;
}
