.list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin: 0 auto 3.5rem;
}

.name {
  margin-bottom: 0.5rem;
}

.description {
  margin-bottom: 1rem;
}

@media (min-width: 23rem) {
  .list {
    grid-template-columns: 1fr 1fr; /* min of 10 */
  }
}

@media (min-width: 62rem) {
  .list {
    grid-template-columns: 1fr 1fr 1fr; /* min of 12 */
  }
}

@media (min-width: 83rem) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr; /* min of 14 */
  }
}

@media (min-width: 108rem) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; /* min of 16 */
  }
}

@media (min-width: 137rem) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /* min of 18 */
  }
}
