.subtitle {
  margin-top: 0.5rem;
}

.error {
  margin-bottom: 1rem;
}

.footer {
  margin-top: 2rem;
  text-align: center;
}

.footer > * {
  display: inline;
  margin-right: 0.5rem;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.errorMessage {
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 0.25rem;
  font-size: 0.9rem;
}
