.button {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 5rem;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 1rem;
  gap: 0.25rem;

  border: none;
}
