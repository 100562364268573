.header {
  padding-top: 0.5rem;
}

.group {
  border-bottom-width: 1px;
}

.group:not(:last-of-type) {
  margin-bottom: 0.5rem;
  border-bottom-style: solid;
}

.flag {
  display: inline-block;
  padding: 0.125rem 0.375rem;

  border-radius: 4px;
}

.subheading {
  margin-top: 0.25rem;
}
