.button.swap {
  display: none;
}

@media (min-width: 44rem) {
  .icon {
    display: none;
  }

  .button.swap {
    display: inline-flex; /* set back to default */
  }
}
