.password:after {
  content: '\02022\02022\02022\02022\02022\02022\02022\02022';
  font-size: 1.2rem;
  line-height: 1rem;
}

.fieldset {
  margin-top: 1rem;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}
