.header {
  display: flex;
  flex-direction: row-reverse;
}

.content {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;

  display: flex;
  flex-direction: column;
}

/* sidebar */
.event {
  display: none;
}

.scrollbar {
  /* leave space for header, nav heading, and backdrop to show */
  max-height: calc(100vh - 4rem - 4rem - 2rem);
}

.faq {
  border-bottom-width: 1px;
  border-bottom-style: solid;

  padding: 0.5rem;
}

.pdfButton {
  display: flex;
  gap: 0.5rem;
  justify-content: left;
}

.branch {
  padding: 1rem;
  margin-top: 1rem;
}

@media (min-width: 44rem) {
  .content {
    margin-left: 16rem; /* allow space for sidebar */
  }

  /* sidebar */
  .sidebar {
    width: 16rem !important; /* override default in Sidebar */

    border-right-width: 1px;
    border-right-style: solid;
  }

  .nav {
    display: flex;
    flex-direction: column;

    height: 100%;
  }

  .event {
    display: initial; /* override mobile style */

    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .faq {
    padding: 1rem;
  }

  .tooltip {
    text-align: center;
  }

  .scrollbar {
    max-height: none; /* override mobile style */
  }
}
