.box {
  padding: 1rem;
}

.title {
  margin-bottom: 0.5rem;
}

.description {
  margin-bottom: 2rem;
}

@media (min-width: 44rem) {
  .box {
    padding: 2rem;
  }
}
